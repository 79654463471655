import { TDetails } from 'keen-slider';
import { useKeenSlider } from 'keen-slider/react';
import clsx from 'clsx';
import Image from 'components/image';
import Video from 'components/video';
import GalleryPagination from 'components/gallery-pagination';
import { graphql } from 'gatsby';
import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import * as styles from './gallery.module.scss';

const Gallery: React.FC<Pick<GatsbyTypes.GalleryFragment, 'slides'>> = ({ slides = [] }) => {
  const { i18n } = useTranslation();
  const [currentSlide, setCurrentSlide] = React.useState<number>(0);
  const [currentIsVideo, setCurrentIsVideo] = React.useState<boolean>(false);
  const [details, setDetails] = React.useState<TDetails>();
  const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
    duration: 1000,
    loop: true,
    slideChanged: (s) => setCurrentSlide(s.details().relativeSlide),
    move: (s) => setDetails(s.details()),
    slides: '.gallery-slide',
  });

  const checkIfCurrentIsVideo = React.useCallback(() => {
    setCurrentIsVideo(!!slides[currentSlide]?.video);
  }, [currentSlide, slides]);

  const getImageStyle = React.useCallback(
    (index: number): React.CSSProperties => {
      if (!details) {
        return {};
      }
      const position = details.positions[index];
      const x = details.widthOrHeight * position.distance * -0.92;

      return {
        transform: `translate3d(${x}px, 0px, 0px)`,
        WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
      };
    },
    [details]
  );
  const getContentStyle = React.useCallback(
    (index: number): React.CSSProperties => {
      if (!details) {
        return {};
      }
      const position = details.positions[index];
      const x = details.widthOrHeight * position.distance * -1.02;

      return {
        transform: `translate3d(${x}px, 0px, 0px)`,
        WebkitTransform: `translate3d(${x}px, 0px, 0px)`,
        opacity: position.portion,
      };
    },
    [details]
  );

  React.useEffect(() => {
    checkIfCurrentIsVideo();
  }, [checkIfCurrentIsVideo, currentSlide]);

  const scrollNext = React.useCallback(() => {
    slider.next();
  }, [slider]);
  const scrollPrev = React.useCallback(() => {
    slider.prev();
  }, [slider]);

  return (
    <div
      ref={sliderRef}
      className={clsx(
        styles.gallerySlider,
        (styles as never)[`gallery${i18n.language.toUpperCase()}`],
        {
          [styles.gallerySliderCurrIsVideo]: currentIsVideo,
        }
      )}
    >
      {slides.length > 1 && (
        <>
          <button
            aria-label={'Previous slide'}
            className={styles.galleryButton}
            onClick={scrollPrev}
          />
          <button
            aria-label={'Next slide'}
            className={clsx(styles.galleryButton, styles.galleryButtonRight)}
            onClick={scrollNext}
          />
        </>
      )}
      {slides.map(
        (slide, index) =>
          slide && (
            <div key={slide.id} className={clsx('gallery-slide', styles.gallerySliderItem)}>
              <div style={getImageStyle(index)} className={styles.gallerySliderItemImageWrapper}>
                {slide.video ? (
                  <Video
                    className={clsx(
                      styles.gallerySliderItemImage,
                      styles.gallerySliderItemImageVideo
                    )}
                    posterUrl={slide.poster?.url}
                    url={slide.video.url as string}
                  />
                ) : (
                  <Image className={styles.gallerySliderItemImage} {...slide.image} />
                )}
              </div>
              <div className={styles.gallerySliderItemText}>
                <div
                  style={getContentStyle(index)}
                  dangerouslySetInnerHTML={{ __html: slide.text as string }}
                />
              </div>
            </div>
          )
      )}
      {slides.length > 1 && (
        <div className={styles.galleryCounter}>
          <GalleryPagination
            currentSlide={currentSlide + 1}
            totalSlides={slides.length}
            size={'large'}
          />
        </div>
      )}
    </div>
  );
};
export default Gallery;

export const query = graphql`
  fragment GallerySlide on DatoCmsGallerySlide {
    id: originalId
    image {
      ...Image
    }
    poster: image {
      url(imgixParams: { h: "900" })
    }
    text
    video {
      url
    }
  }
  fragment Gallery on DatoCmsGallery {
    __typename
    id: originalId
    backgroundColor
    showFigure
    slides {
      ...GallerySlide
    }
  }
`;
