import clsx from 'clsx';
import Column from 'components/column';
import { graphql } from 'gatsby';
import React from 'react';
import { capitalize } from 'utils/functions';

import * as styles from './spacing.module.scss';

type SpacingProps = Partial<Pick<GatsbyTypes.DatoCmsSpacing, 'backgroundColor' | 'border'>> & {
  insetColumn?: boolean;
};

const Spacing: React.FC<SpacingProps> = ({
  backgroundColor = 'light',
  border,
  insetColumn = false,
}) => {
  const classNames = clsx(
    styles.spacing,
    { [styles.withBorder]: border },
    (styles as never)[`spacing${capitalize(backgroundColor)}`]
  );

  return (
    <section className={classNames}>
      {insetColumn && (
        <div className={styles.blueColumn}>
          <Column xl={4} lg={4} md={4} sm={2} xs={2} />
        </div>
      )}
    </section>
  );
};

export default Spacing;

export const query = graphql`
  fragment Spacing on DatoCmsSpacing {
    __typename
    id: originalId
    backgroundColor
    border
  }
`;
