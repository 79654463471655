// extracted by mini-css-extract-plugin
export var galleryButton = "gallery-module--galleryButton--Zz2Yk";
export var galleryButtonRight = "gallery-module--galleryButtonRight--Fsz+9";
export var galleryEN = "gallery-module--galleryEN--puyY-";
export var galleryCounter = "gallery-module--galleryCounter--zQc0h";
export var gallerySlider = "gallery-module--gallerySlider--SiLAu";
export var gallerySliderCurrIsVideo = "gallery-module--gallerySliderCurrIsVideo--kTZie";
export var gallerySliderItem = "gallery-module--gallerySliderItem--FkJiE";
export var gallerySliderItemText = "gallery-module--gallerySliderItemText--+lVLc";
export var gallerySliderItemImageVideo = "gallery-module--gallerySliderItemImageVideo--3BsTO";
export var gallerySliderItemImage = "gallery-module--gallerySliderItemImage--7MY+j";
export var gallerySliderItemImageWrapper = "gallery-module--gallerySliderItemImageWrapper--iRasB";