import clsx from 'clsx';
import React from 'react';

import * as styles from './gallery-pagination.module.scss';

export type GalleryPaginationProps = {
  className?: string;
  currentSlide: number;
  totalSlides: number;
  size?: 'normal' | 'large';
};

const GalleryPagination: React.FC<GalleryPaginationProps> = ({
  className,
  currentSlide,
  totalSlides,
  size = 'normal',
}) => {
  const El: keyof JSX.IntrinsicElements = size === 'normal' ? 'h3' : 'h1';

  return (
    <El className={clsx(className, styles.pagination)}>
      <span>{String(currentSlide).padStart(2, '0')}</span>
      {'/'}
      {String(totalSlides).padStart(2, '0')}
    </El>
  );
};

export default GalleryPagination;
