import clsx from 'clsx';
import React from 'react';
import ReactPlayer from 'react-player';
import PlayIcon from 'images/svg/play.inline.svg';
import PlayIconEn from 'images/svg/play-en.inline.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import * as styles from './video.module.scss';

type VideoComponentProps = {
  url: string;
  className?: string;
  posterUrl?: string;
  onLoad?: () => void;
};

const Video: React.FC<VideoComponentProps> = ({ url, className, posterUrl, onLoad }) => {
  const { i18n } = useTranslation();
  const [playing, setPlaying] = React.useState<boolean>(false);

  return (
    <div className={clsx(styles.videoWrapper, { [styles.videoPlaying]: playing })}>
      {!playing && i18n.language !== 'en' && (
        <PlayIcon className={styles.videoPlayButton} onClick={() => setPlaying(true)} />
      )}
      {!playing && i18n.language === 'en' && (
        <PlayIconEn className={styles.videoPlayButton} onClick={() => setPlaying(true)} />
      )}
      <ReactPlayer
        className={clsx(styles.video, className)}
        pip={false}
        url={url}
        controls={playing}
        playing={playing}
        onEnded={() => setPlaying(false)}
        config={{
          file: {
            attributes: { poster: posterUrl ? posterUrl : '' },
          },
        }}
        onReady={() => {
          if (onLoad) onLoad();
          // IE11 Polyfill for object-fit on video element
          const vids = Array.from(document.querySelectorAll(`.${styles.video} video`));
          if (vids.length > 0) {
            vids.forEach((vid) => {
              vid.setAttribute('data-object-fit', '');
            });
            window.objectFitPolyfill();
          }
        }}
        width={'100%'}
        height={'100%'}
      />
    </div>
  );
};

export default Video;
